<template>
  <div class="downloads">
    <div class="pa-2 ma-2">
      <p class="text-h4">Downloads</p>
      <template>
        <v-card flat>
          <v-card-title>             
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>            
          <v-data-table
            dense
            :headers="headers"
            :items="files"
            :loading="loading"  
            :footer-props="{itemsPerPageOptions: [10, 50, 100]}"
            :search="search"
          >
            <template v-slot:item.hyperlink="{ item }">
              <v-btn class="mx-2" fab text dark small color="primary" @click="getFile(item.hyperlink)">
                <v-icon>mdi-download-outline</v-icon>  
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </template>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      search: '',
      headers: [
        { text: 'File', align: 'left', filterable: false,value: 'filename'},
        { text: 'Creation Time', value: 'creation_time'},
        { text: 'File Size', value: 'filesize', sortable: false},
        { text: 'Download link', value: 'hyperlink', sortable: false}
      ],
      files: [],
      loading: true
    }
  },
  methods : {
    getDownloads() {
      this.loading = true
      var url = this.$webUrl+"/api/downloads"
      fetch(url).then(async response => {
        const data = await response.json();
        this.loading=false;  
        if(!response.ok)
        {      
          this.$root.toast.show({message: 'Error getting downloads', color: 'error'})
          return
        }
        this.files = data.downloads
      })   
    },
    getFile(filename) {
      window.open(this.$webUrl+"/api/downloads/"+filename);      
    }    
  },
  beforeMount() {
    this.getDownloads();
  }
}
</script>